

.App {
  text-align: center;
  background-color: #000000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 0 100px;
}

.home-title {
  font-size: 56px;
  margin-bottom: 0;
  font-weight: 700;
  background: -webkit-linear-gradient(180deg, rgba(140,140,140,1) 0%, rgba(255,255,255,1) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: 800px;
}

.home-desc{
  font-weight:100;
  width:"800px"
}

.button {
  padding: 20px 60px;
  background-color: #2b5fc4;
  color: #fff;
  border-radius: 15px;
  font-size: 18px;
  outline: none;
  border: none;
  white-space:nowrap;
  cursor: pointer;
}
button:hover{
  opacity: .8;
}

.error{
  font-size: 14px;
  margin: 0;
  text-align: left;
  color: red;
}





@media (max-width: 600px) {
  .App {
    padding: 0 20px;
  }
  .home-title {
    width: 100%;
    line-height: 70px;
    margin-bottom: 10px;
  }
  .home-desc{
    width: 100%;
  }
  .container {
    max-width: 100%;
}
}
.iframe-div{
  width: 100%;
  height: 300px;
  iframe{
      width: 100%;
      height: 100%;
  }
}